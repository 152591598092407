<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <h4 class="mb-2">
          {{ $t("servers.webshops") }}
        </h4>
        <div class="items">
          <button
            v-for="webshop in webshops"
            :key="webshop.id"
            class="btn w-100 text-start"
            :class="[
              webshop.id === selectedWebshop?.id ? 'btn-primary' : 'btn-simple',
            ]"
            v-on:click="selectedWebshop = webshop"
          >
            {{ webshop.name }}
          </button>
          <button
            class="btn w-100 text-start"
            :class="!selectedWebshop?.id ? 'btn-primary' : 'btn-simple'"
            v-on:click="selectedWebshop = {}"
          >
            {{
              !selectedWebshop?.id && selectedWebshop?.name
                ? "+ " + selectedWebshop?.name
                : "+ new webshop"
            }}
          </button>
        </div>
        <h4 class="my-2">
          {{ $t("webshops.setting") }}
        </h4>
        <div
          class="items"
          v-scroll-spy-link="{ selector: 'a', class: 'btn-primary' }"
          v-scroll-spy-active="{ selector: 'a div', class: 'btn-primary' }"
        >
          <div class="items">
            <a href="#basics"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("warehouses.basics") }}
              </div></a
            >
            <a href="#warehouses"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("webshops.warehouses") }}
              </div></a
            >
            <a href="#delivery"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("order.delivery2") }}
              </div></a
            >
            <a href="#payment"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("order.payment") }}
              </div></a
            >
          </div>
        </div>
      </nav>
    </div>
    <div
      class="col-md-8 col-lg-10"
      v-scroll-spy="{ offset: 120 }"
      v-if="selectedWebshop"
    >
      <section id="basics">
        <h2>
          {{ $t("webshops.webshopSettings") }}
        </h2>
        <h4>
          {{ $t("webshops.basics") }}
        </h4>
        <div class="row text-start custom-input whitebg-input mt-4">
          <div class="row mb-3">
            <div class="col-5 mb-3">
              <label class="form-label bold-12 text-gray">
                {{ $t("session.name") }}
              </label>
              <input
                type="text"
                class="form-control remove-padding"
                :placeholder="$t(`session.name`)"
                v-model="selectedWebshop.name"
              />
            </div>
            <div class="col-4 mb-3">
              <label class="form-label bold-12 text-gray">
                {{ $t("webshops.domain") }}
              </label>
              <input
                type="text"
                class="form-control remove-padding"
                placeholder="webshop.com"
                v-model="selectedWebshop.domain"
              />
            </div>
            <div class="col-3 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("webshops.urlSlug")
              }}</label>
              <input
                type="text"
                class="form-control remove-padding"
                placeholder="w-hu"
                v-model="selectedWebshop.slug"
              />
            </div>
            <div class="col-3 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("warehouses.currency")
              }}</label>
              <v-select
                v-model="selectedWebshop.currency_code"
                :options="enabledCurrencies"
                label="code"
                :placeholder="$t(`webshops.allStorages`)"
                :reduce="(o) => o.code"
                :getOptionKey="(c) => c.code"
              />
            </div>
            <div class="col-6 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("warehouses.company")
              }}</label>
              <v-select
                v-model="selectedWebshop.company_id"
                :options="companies"
                label="name"
                :placeholder="$t(`webshops.selectOne`)"
                :reduce="(o) => o.id"
              />
            </div>
            <div class="col-3 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("webshops.language")
              }}</label>
              <v-select
                v-model="selectedWebshop.default_language_code"
                :options="languages"
                label="name"
                :placeholder="$t(`webshops.selectOne`)"
                :reduce="(o) => o.code"
                :getOptionKey="(c) => c.code"
              />
            </div>
            <div class="col-3 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("register.email")
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="info@myshop.com"
                v-model="selectedWebshop.email"
              />
            </div>
            <div class="col-3 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("warehouses.phone")
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="+101234567"
                v-model="selectedWebshop.phone"
              />
            </div>
            <div class="col-3 mb-3">
              <label class="form-label bold-12 text-gray">{{
                $t("webshops.storesInThisCountry")
              }}</label>
              <v-select
                v-model="selectedWebshop.country_id"
                :options="countries"
                label="name"
                :placeholder="$t(`webshops.selectOne`)"
                :reduce="(o) => o.id"
              />
            </div>
            <div class="col-3 mb-3">
              <label class="form-label bold-12 text-gray">
                Allow return duration
              </label>
              <div class="fix-end-input">
                <input
                  type="number"
                  class="form-control"
                  v-model="selectedWebshop.return_allowed_days"
                  ref="imp"
                  step="1"
                  :min="1"
                />
                <span>days</span>
              </div>
            </div>
            <div class="col-12 mb-3">
              <label class="cstm-check d-flex" for="ct-1">
                <span class="regular-12 text-black mt-3">
                  Product return service
                </span>
                <input
                  type="checkbox"
                  v-model="selectedWebshop.is_return"
                  value="1"
                  id="ct-1"
                />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-12 mb-3">
              <label class="cstm-check d-flex" for="ct-2">
                <span class="regular-12 text-black mt-3">
                  Product change service
                </span>
                <input
                  type="checkbox"
                  v-model="selectedWebshop.is_change"
                  value="1"
                  id="ct-2"
                />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-12 mb-3">
              <label class="cstm-check d-flex" for="ct-3">
                <span class="regular-12 text-black mt-3">
                  International COD service
                </span>
                <input
                  type="checkbox"
                  v-model="selectedWebshop.is_international_cod"
                  value="1"
                  id="ct-3"
                />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-12 mb-3">
              <label class="cstm-check d-flex" for="ct-6">
                <span class="regular-12 text-black mt-3">
                  Use default language
                </span>
                <input
                  type="checkbox"
                  v-model="selectedWebshop.use_default_language"
                  value="1"
                  id="ct-6"
                />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-12 mb-3">
              <label class="cstm-check d-flex" for="ct-4">
                <span class="regular-12 text-black mt-3">
                  Group delivery methods
                </span>
                <input
                  type="checkbox"
                  v-model="selectedWebshop.is_group_delivery_methods"
                  value="1"
                  id="ct-4"
                />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mb-3 d-grid justify-content-end me-4">
          <button
            type="submit"
            class="btn btn-type-2 btn-normal"
            v-on:click="saveBasic"
          >
            <span>{{ $t("orderChange.save") }}</span>
          </button>
        </div>
        <hr class="my-4" />
      </section>
      <section id="warehouses" v-if="selectedWebshop?.id">
        <h4>{{ $t("webshops.warehouses") }}</h4>
        <div class="row">
          <div class="col-6">
            <h5>Delivery</h5>
            <draggable
              class="dragArea row custom-input pt-2"
              tag="ul"
              :list="selectedWebshop.deliveryWarehouses"
              :group="{ name: 'g1' }"
              item-key="id"
              v-bind="dragOptions"
              :forceFallback="true"
              :scroll="true"
              :scrollSensitivity="100"
            >
              <template #item="{ element, index }">
                <li class="row">
                  <div class="col-3">{{ ordinal_suffix_of(index) }}</div>
                  <div class="col">{{ element.name }}</div>
                  <div class="col-auto">
                    <button
                      class="btn btn-transparent"
                      v-on:click="deleteWarehouse('delivery', element.id)"
                    >
                      <BaseIcon name="trash" />
                    </button>
                  </div>
                </li>
              </template>
            </draggable>
            <div class="row align-items-end">
              <div class="col">
                <label class="form-label bold-12 text-gray">{{
                  $t("webshops.warehouse")
                }}</label>
                <v-select
                  v-model="selectedDeliveryWebshop"
                  :options="warehouses"
                  label="name"
                  :placeholder="$t(`webshops.selectOne`)"
                />
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-transparent"
                  v-on:click="addWarehouse('delivery')"
                >
                  <BaseIcon name="add" />
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <h5>Return</h5>
            <draggable
              class="dragArea row custom-input pt-2"
              tag="ul"
              :list="selectedWebshop.returnWarehouses"
              :group="{ name: 'g1' }"
              item-key="id"
              v-bind="dragOptions"
              :forceFallback="true"
              :scroll="true"
              :scrollSensitivity="100"
            >
              <template #item="{ element, index }">
                <li class="row">
                  <div class="col-3">{{ ordinal_suffix_of(index) }}</div>
                  <div class="col">{{ element.name }}</div>
                  <div class="col-auto">
                    <button
                      class="btn btn-transparent"
                      v-on:click="deleteWarehouse('return', element.id)"
                    >
                      <BaseIcon name="trash" />
                    </button>
                  </div>
                </li>
              </template>
            </draggable>
            <div class="row align-items-end">
              <div class="col">
                <label class="form-label bold-12 text-gray">{{
                  $t("webshops.warehouse")
                }}</label>
                <v-select
                  v-model="selectedReturnWebshop"
                  :options="warehouses"
                  label="name"
                  :placeholder="$t(`webshops.selectOne`)"
                />
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-transparent"
                  v-on:click="addWarehouse('return')"
                >
                  <BaseIcon name="add" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 d-grid justify-content-end me-4">
          <button
            type="submit"
            class="btn btn-type-2 btn-normal"
            v-on:click="saveWarehouses"
          >
            <span>{{ $t("orderChange.save") }}</span>
          </button>
        </div>
        <hr class="my-4" />
      </section>

      <section id="delivery" v-if="selectedWebshop?.id">
        <h4>{{ $t("order.delivery2") }}</h4>
        <OneByOneTable
          v-model:rows="deliveryRows"
          v-model:items="delivery"
          :show-add="false"
          :show-del="false"
          type="delivery"
        />
        <div class="row mb-3 d-grid justify-content-end me-4">
          <button
            type="submit"
            class="btn btn-type-2 btn-normal"
            v-on:click="saveDelivery"
          >
            <span>{{ $t("orderChange.save") }}</span>
          </button>
        </div>
        <hr class="my-4" />
      </section>
      <section id="payment" v-if="selectedWebshop?.id">
        <h4>{{ $t("order.payment") }}</h4>
        <OneByOneTable
          v-model:rows="paymentRows"
          v-model:items="payment"
          :show-add="false"
          :show-del="false"
          type="payment"
        />
        <div class="row mb-3 d-grid justify-content-end me-4">
          <button
            type="submit"
            class="btn btn-type-2 btn-normal"
            v-on:click="savePayment"
          >
            <span>{{ $t("orderChange.save") }}</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import OneByOneTable from "../lists/OneByOneTable";
import http from "@/modules/http";
import { useStore } from "vuex";
import { defineAsyncComponent } from "vue";
import draggable from "vuedraggable";
import BaseIcon from "../icons/BaseIcon.vue";

export default {
  name: "Webshops",
  components: { BaseIcon, OneByOneTable, draggable },
  data() {
    return {
      store: useStore(),
      webshops: [],
      companies: [],
      selectedWebshop: undefined,
      paymentRows: [
        {
          name: "Name",
          key: "name",
          type: "string",
          editable: false,
          show: true,
        },
        {
          name: "Provider",
          key: "app_name",
          type: "string",
          editable: false,
          show: true,
        },
        {
          name: "Type",
          key: "type",
          type: "enum",
          label: "name",
          values: {
            1: { id: 1, name: "Prepaid" },
            2: { id: 2, name: "Payment on delivery" },
            3: { id: 3, name: "Cash" },
            4: { id: 4, name: "Bank transfer" },
            5: { id: 5, name: "Card on shop" },
          },
          editable: false,
          show: true,
        },
        {
          name: "Active",
          key: "active",
          type: "boolean",
          show: true,
        },
      ],
      payment: [],
      payments: [],
      deliveryRows: [
        {
          name: "Name",
          key: "name",
          type: "string",
          editable: false,
          show: true,
        },
        {
          name: "Type",
          key: "type",
          type: "enum",
          label: "name",
          values: {
            1: { id: 1, name: "Normal delivery" },
            2: { id: 2, name: "Pick-up point" },
            3: { id: 3, name: "Return" },
            4: { id: 4, name: "Change" },
            5: { id: 5, name: "Next day delivery" },
            6: { id: 6, name: "Saturday delivery" },
            7: { id: 7, name: "Flexible day delivery" },
            8: { id: 8, name: "Timeslot delivery" },
          },
          editable: false,
          show: true,
        },
        {
          name: "Delivery to",
          key: "countries",
          type: "object",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/DeliveryCountriesSetting")
          ),
          show: true,
        },
        {
          name: "Active",
          key: "active",
          type: "boolean",
          show: true,
        },
      ],
      deliveries: [],
      delivery: [],
      selectedDeliveryWebshop: null,
      selectedReturnWebshop: null,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      columnNumbers: [
        this.$t("serialNumbers.first"),
        this.$t("serialNumbers.second"),
        this.$t("serialNumbers.third"),
        this.$t("serialNumbers.fourth"),
        this.$t("serialNumbers.fifth"),
        this.$t("serialNumbers.sixth"),
        this.$t("serialNumbers.seventh"),
        this.$t("serialNumbers.eighth"),
        this.$t("serialNumbers.ninth"),
        this.$t("serialNumbers.tenth"),
      ],
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    enabledCurrencies() {
      const store = useStore();
      return store.state.currencies;
    },
    languages() {
      const store = useStore();
      return store.state.languages;
    },
    countries() {
      const store = useStore();
      return store.state.countries;
    },
  },
  mounted() {
    this.load();
  },
  watch: {
    selectedWebshop() {
      this.calcDelivery();
      this.calcPayments();
    },
  },
  methods: {
    ordinal_suffix_of(i, asText = true) {
      if (asText && i < 10) {
        return this.columnNumbers[i];
      }
      i++;
      if (this.$i18n.locale !== "en") {
        return i + ".";
      }
      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    },
    calcDelivery() {
      let rows = this.deliveries;
      let rowRefs = [];
      for (const key in rows) {
        rowRefs[rows[key].id] = rows[key];
        rowRefs[rows[key].id].active = false;
        rowRefs[rows[key].id].countries = {
          active: [],
          all: rowRefs[rows[key].id].supported_coutries,
        };
      }

      if (this.selectedWebshop?.id > 0) {
        for (const method of this.selectedWebshop.deliveryMethods) {
          rowRefs[method.id].active = true;
          if (method.pivot.country_id !== null) {
            rowRefs[method.id].countries.active.push(method.pivot.country_id);
          }
        }
      }
      this.delivery = rows;
    },
    calcPayments() {
      let rows = this.payments;
      let rowRefs = [];
      for (const key in rows) {
        rowRefs[rows[key].id] = rows[key];
        rowRefs[rows[key].id].active = false;
      }

      if (this.selectedWebshop?.id > 0) {
        for (const method of this.selectedWebshop.paymentMethods) {
          rowRefs[method.id].active = true;
        }
      }
      this.payment = rows;
    },
    load() {
      let loaded = 0;
      http.fetch("/webshops?hide-global-payments=1").then((data) => {
        this.webshops = data;
        loaded++;
        if (loaded === 3) {
          this.selectedWebshop = this.webshops[0];
        }
      });
      http.fetch("/companies").then((data) => {
        this.companies = data;
      });
      http.fetch("/deliveries/methods/").then((data) => {
        loaded++;
        this.deliveries = data;
        if (loaded === 3) {
          this.selectedWebshop = this.webshops[0];
        }
      });
      http.fetch("/payments/methods/").then((data) => {
        loaded++;
        this.payments = data;
        if (loaded === 3) {
          this.selectedWebshop = this.webshops[0];
        }
      });
    },
    saveBasic() {
      let data = Object.assign({}, this.selectedWebshop);
      delete data.paymentMethods;
      delete data.deliveryMethods;
      delete data.country;
      delete data.deliveryWarehouses;
      delete data.returnWarehouses;
      data.is_return = data.is_return ? 1 : 0;
      data.is_change = data.is_change ? 1 : 0;
      data.is_international_cod = data.is_international_cod ? 1 : 0;
      data.is_group_delivery_methods = data.is_group_delivery_methods ? 1 : 0;
      data.use_default_language = data.use_default_language ? 1 : 0;

      if (this.selectedWebshop.id) {
        http.fetch("/webshops/" + this.selectedWebshop.id, data, true, "PUT");
      } else {
        http.fetch("/webshops", data, true);
      }
    },
    saveDelivery() {
      let data = {
        deliveries: [],
      };

      for (const delivery of this.delivery) {
        if (delivery.active && delivery.countries.active.length > 0) {
          data.deliveries.push({
            id: delivery.id,
            countries: delivery.countries.active,
          });
        }
      }

      http
        .fetch(
          "/webshops/" + this.selectedWebshop.id + "/deliveries",
          data,
          true
        )
        .then(() => {
          http.fetch("/webshops?hide-global-payments=1").then((data) => {
            this.webshops = data;
          });
        });
    },
    savePayment() {
      let data = {
        payments: [],
      };

      for (const payment of this.payment) {
        if (payment.active) {
          data.payments.push({
            id: payment.id,
          });
        }
      }

      http.fetch(
        "/webshops/" + this.selectedWebshop.id + "/payments",
        data,
        true
      );
    },
    saveWarehouses() {
      let data = {
        delivery: [],
        return: [],
      };

      for (const warehouse of this.selectedWebshop.deliveryWarehouses) {
        data.delivery.push(warehouse.id);
      }
      for (const warehouse of this.selectedWebshop.returnWarehouses) {
        data.return.push(warehouse.id);
      }

      http.fetch(
        "/webshops/" + this.selectedWebshop.id + "/warehouses" + "",
        data,
        true
      );
    },
    addWarehouse(type) {
      let e = {};
      let webshop = null;
      if (type === "delivery") {
        e = this.selectedWebshop.deliveryWarehouses;
        webshop = this.selectedDeliveryWebshop;
      } else if (type === "return") {
        e = this.selectedWebshop.returnWarehouses;
        webshop = this.selectedReturnWebshop;
      }
      if (webshop === null) {
        return;
      }
      e.push({ id: webshop.id, name: webshop.name });
      webshop = null;
    },
    deleteWarehouse(type, id) {
      let e = {};
      if (type === "delivery") {
        e = this.selectedWebshop.deliveryWarehouses;
      } else if (type === "return") {
        e = this.selectedWebshop.returnWarehouses;
      }
      let item = e.findIndex((i) => i.id == id);
      if (item === undefined) {
        return;
      }
      e.splice(item, 1);
    },
  },
};
</script>
